/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiArrowLeftSLine } from "react-icons/ri"

import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout sx={errorStyles.notFoundPage}>
    <Seo title="Page not found" />
    <div sx={errorStyles.wrapper}>
      <header>
        <h1 sx={{ color: "global.headingColor", fontSize: "24px" }}>Oop. 404.</h1>
        <p sx={{ color: "global.textColor" }}>
          Not sure what you were looking for, sorry. 
        </p>
      </header>
      <Link to="/" sx={{ variant: "variants.button.primary", mt: 2 }}>
        <RiArrowLeftSLine sx={{ mr: 2 }} />
        Back to Home
      </Link>
    </div>
  </Layout>
)

export default NotFound

const errorStyles = {
  notFoundPage: {
    pt: "130px",
    minHeight: "80vh",
  },
  wrapper: {
    variant: "variants.container",
    textAlign: "center",
  },
  icon: {
    fontSize: "128px",
    color: "brand",
  },
}
